<template>
  <main class="dashboard links">
    <div class="filters d-flex">
      <MultiSelect
          v-model="filters.tone"
          :options="Object.keys($TONES).filter(x => !$TONES[x].no_filter).map(x => {
        return {id: x, name: $TONES[x].name}
      })"
          :multi="false"
          :only_value="true"
          name="Тональность">
      </MultiSelect>
      <MultiSelect
          v-model="filters.state"
          :options="$STATES"
          :multi="false"
          :only_value="true"
          name="ТОП">
      </MultiSelect>
      <date-picker
          v-model="start_date"
          locale="ru"
          :clearable="false"
          format="dd.MM.yyyy"
          model-type="yyyy-MM-dd"
          auto-apply
          :enable-time-picker="false"
      ></date-picker>
      <date-picker v-model="end_date"
                   locale="ru"
                   :clearable="false"
                   format="dd.MM.yyyy"
                   model-type="yyyy-MM-dd"
                   auto-apply
                   :enable-time-picker="false"
      ></date-picker>
      <div class="form-check form-switch">
        <input class="form-check-input" v-model="uniq" type="checkbox" role="switch" id="uniq" checked>
        <label class="form-check-label" for="uniq">Уникальные ссылки</label>
      </div>
      <button class="btn btn-outline-dark" v-on:click="getData()">Показать</button>
    </div>
    <div class="filters d-flex">
      <CountryChoice v-bind:active_country.sync="active_country"
                     v-bind:countries="countries"
                     v-bind:changeCountry="(country) => active_country = country"></CountryChoice>
    </div>


    <table class="table table-hover table-bordered table-responsive">
      <tr>
        <td>
          <div class="report">
            <button class="btn btn-sm btn-dark" v-if="!report" @click="createReportData">Отчёт</button>
            <template v-if="report">
              <date-picker v-model="report.date"
                           locale="ru"
                           :clearable="false"
                           format="dd.MM.yyyy"
                           model-type="yyyy-MM-dd"
                           auto-apply
                           :enable-time-picker="false"
              ></date-picker>
              <button class="btn btn-sm btn-dark" @click="getReport">Скачать</button>
              <button class="btn btn-sm btn-secondary" @click="report=null">Отмена</button>
            </template>
          </div>
        </td>
        <template v-for="date in dates">

          <th colspan="2" class="text-center p-1">
            <small>{{ $moment(date).format("DD.MM.YY") }}</small>
          </th>
        </template>
      </tr>
      <tr>
        <td></td>
        <template v-for="_ in dates">
          <th class="text-center p-1">
            <i class="yandex"></i>
          </th>
          <th class="text-center p-1">
            <i class="google"></i>
          </th>
        </template>
      </tr>

      <template v-for="(project, p_key) in calc_projects">

        <tr class="gray">
          <td class="gray d-flex border-0 justify-content-between" style="max-width: 500px;">
            <div class="project-name">
              <input type="checkbox" v-if="report" :value="project.id" v-model="report.projects">
              <div class="btn btn-sm btn-outline-dark p-0" @click="project.open = !project.open">
                <i v-if="!project.open" class="bi bi-plus"></i>
                <i v-else class="bi bi-dash-lg"></i>
              </div>
              <router-link :to="`/projects/${project.id}`">{{ project.name }}</router-link>
            </div>
            <span>
                <sup>план</sup><br>
                <sup>факт</sup>
              </span>
          </td>
          <template v-for="date in dates">
            <td colspan="2" class="text-center">
                      <span
                          :class="`${project.dates[date].forecast<project.dates[date].total?'text-danger':'text-dark'}`">
                           {{ project.dates[date].forecast }}

                      </span><br>
              <span class="text-dark">
                        {{ project.dates[date].total }}
                      </span>
            </td>
          </template>
        </tr>

        <template v-if="project.open" v-for="keyword in project.keywords">
          <tr>
            <td>{{ keyword.name }}</td>
            <template v-for="date in dates">
              <td class="text-center">

                <span class="text-dark"> {{ keyword.dates[date].yandex }}</span>
              </td>
              <td class="text-center">
                <span class="text-dark"> {{ keyword.dates[date].google }}</span>
              </td>
            </template>

          </tr>

        </template>


      </template>

    </table>


  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import moment from 'moment';

export default {
  name: 'FullInfo',

  data() {
    return {
      projects: [],
      forecasts: [],
      links: [],
      dates: [],
      countries: [],
      report: null,
      active_country: null,
      link: '',
      filters: {
        tone: 'negative',
        state: 20,
      },
      uniq: false,
      start_date: moment().add(-7, "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    }
  },
  watch: {
    active_country: function (val, old) {
      if (old) this.getData()
    },
  },
  computed: {

    // dates() {
    //   let dateArr = []
    //   let date = moment(this.start_date)
    //   while (!moment(date).isAfter(this.end_date)) {
    //     dateArr.push(date.format("YYYY-MM-DD"));
    //     date = moment(date).add(1, "days")
    //   }
    //   return dateArr;
    // },
    calc_projects() {
      this.projects.forEach(project => {
        let results = this.links.filter(x => x.result__keyword__project_id === project.id),
            forecasts = this.forecasts.filter(x => x.project_id === project.id),
            keywords = Object.values(
                results.map(x => {
                  return {name: x.result__keyword__name, id: x.result__keyword_id}
                }).reduce((acc, obj) => ({...acc, [obj.id]: obj}), {})
            );
        keywords.forEach(keyword => {
          keyword.dates = {};
          this.dates.forEach(date => {
            keyword.dates[date] = {}
            this.$ENGINES.forEach(engine => {
              keyword.dates[date][engine] = results.filter(link => link.result__engine__code === engine && link.date_create__date == date && link.result__keyword_id === keyword.id).length
            })
          })
        })
        project.dates = {}
        this.dates.forEach(date => {
          project.dates[date] = {}
          project.dates[date].forecast = forecasts.find(x => x.date === date)?.inner || "-"
          project.dates[date].total = keywords.map(x => [x.dates[date].yandex, x.dates[date].google]).flat(1).reduce((a, b) => a + b, 0)
        })
        project.keywords = keywords;

      })

      return this.projects
    }

  },
  mounted: function () {
    let $this = this;
    ProjectApi.countries().then(response => {
      $this.countries = response.results;
      $this.active_country = $this.countries[0];
      // this.getData();
    });
    ProjectApi.get_select_list().then(response => this.projects = response);


  },
  methods: {
    createReportData() {
      this.report = {
        date: this.end_date,
        country_code: this.active_country.code,
        projects: this.projects.map(x => x.id)
      }
    },
    getReport() {
      const $this = this;
      ProjectApi.get_full_stat_report(this.report).then(() => {
        $this.$notify({
          type: 'success ',
          text: 'Процесс создания отчёта запущен. Ссылка на отчёт будет доступна в уведомлениях'
        });
        $this.report = null;
      })


    },
    getData: function () {
      let dateArr = []
      let date = moment(this.start_date)
      while (!moment(date).isAfter(this.end_date)) {
        dateArr.push(date.format("YYYY-MM-DD"));
        date = moment(date).add(1, "days")
      }
      this.dates = dateArr;
      let $this = this, filter = this.filters;
      if (this.uniq) filter.uniq = true;
      else delete filter.uniq;
      filter.date_start = this.start_date;
      filter.date_end = this.end_date;
      filter.country_code = $this.active_country.code;
      let f_string = new URLSearchParams(this.filters).toString();
      ProjectApi.get_full_stat(f_string).then(response => {
        $this.forecasts = response.forecasts;
        $this.links = response.links;
      })
    }
  }
}

</script>

<style scoped>
.card {
  white-space: nowrap;
  overflow-x: scroll;
  padding-bottom: 50px;
}

.col-t.one {
  width: 80px;
  margin: 0;
}

.col-t.two {
  width: 160px;
  margin: 0;
}

.col-t {
  text-align: center;
  height: 30px;
}

.col-t, .col-t-3 {
  border: 1px solid #ccc;
  padding: 2px;
}

.no-b {
  border: none;
}

.col-t, .col-t-3 {
  display: inline-block;
}

.col-t-3 {
  width: 350px;
  white-space: normal;
}

.row-t {
  display: table;
}

.fixed, tr td:first-child {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
}

.gray {
  background: #eee !important;
}

.fixed:not(.gray-bg) {
  background: #fff;
}

.fixed + div {
  /*margin-left: 350px!important;*/
}

.col-t-3.no-b.fixed {
  height: 40px;
  margin-bottom: -10px;
  border-right: 1px solid #ccc;
}

.uniq-p {
  padding-top: 40px;
}

.report {
  display: flex;
  gap: 5px;
  z-index: 100000;
  position: relative;
}

.project-name {
  display: flex;
  gap: 5px;
  align-items: center;
}
</style>

